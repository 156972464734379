import React from "react";
import { useStaticQuery } from "gatsby";
import "../../../css/bootstrap.css";
import "../../../css/common.css";
import "../../../css/modal.css";
import { WalkThroughSection } from "../../../components/projects/Project";

const useCrownData = () =>
  useStaticQuery(graphql`
    query {
      green: file(
        relativePath: {
          eq: "on-going-projects/crown-residence/green-open-spaces/1.jpg"
        }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ongoingJson(slug: { eq: "crown-residence" }) {
        banner {
          childImageSharp {
            fluid {
              src
            }
          }
        }
        slug
        rera
        title
        tagline
        brouchureUrl
        video {
          type
          link
        }
        sections {
          title
          synopsis
          specification {
            a
            q
          }
          covers {
            link
            image {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          highlights
          features {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            points
          }
          gMapUrl
          gallery {
            title
            images {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          walkthrough {
            title
            urls
          }
        }
      }
    }
  `);
const WalkThrough = () => {
  const { ongoingJson } = useCrownData();
  const project = ongoingJson;
  const sections = project.sections || [];
  const current = sections[0] || {};
  return <WalkThroughSection walk_through={current.walkthrough} />;
};

export default WalkThrough;
